//
// Masonry grid
// based on https://vestride.github.io/Shuffle/
// --------------------------------------------------


// Base styles

.masonry-grid[data-columns] {
  overflow: hidden;
  margin: {
    right: -($grid-gutter-width * .5);
    left: -($grid-gutter-width * .5);
  }

  @include clearfix();

  .masonry-grid-item {
    float: left;
    width: 100%;
    padding: {
      right: $grid-gutter-width * .5;
      bottom: $grid-gutter-width;
      left: $grid-gutter-width * .5;
    }
  }
}

.masonry-grid[data-columns="1"] .masonry-grid-item {
  width: 100%;
}

@include media-breakpoint-up(sm) {
  .masonry-grid[data-columns="2"],
  .masonry-grid[data-columns="3"],
  .masonry-grid[data-columns="4"],
  .masonry-grid[data-columns="5"],
  .masonry-grid[data-columns="6"] {
    .masonry-grid-item { width: 50%; }
  }
}
@include media-breakpoint-up(md) {
  .masonry-grid[data-columns="4"],
  .masonry-grid[data-columns="5"],
  .masonry-grid[data-columns="6"] {
    .masonry-grid-item { width: 33.333%; }
  }
}
@include media-breakpoint-up(lg) {
  .masonry-grid[data-columns="3"],
  .masonry-grid[data-columns="4"],
  .masonry-grid[data-columns="5"],
  .masonry-grid[data-columns="6"] {
    .masonry-grid-item { width: 33.333%; }
  }
}
@include media-breakpoint-up(xl) {
  .masonry-grid[data-columns="4"],
  .masonry-grid[data-columns="5"],
  .masonry-grid[data-columns="6"] {
    .masonry-grid-item { width: 25%; }
  }
}
@include media-breakpoint-up(xxl) {
  .masonry-grid[data-columns="5"] {
    .masonry-grid-item { width: 20%; }
  }
  .masonry-grid[data-columns="6"] {
    .masonry-grid-item { width: 16.666%; }
  }
}
